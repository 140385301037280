body {
  margin: 0;
  font-family: 'Jost', 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  font-weight: bolder;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.carousel{
  height: 350px !important;
}

.notificacion{
  background: #2A6E8A;
  color: white;
  height: 226px;
  width: 50%;
  text-align: center;
  padding: 50px;
  border-radius: 15px;
  box-shadow: 10px 12px 31px -14px rgba(0,0,0,0.75);
  -webkit-box-shadow: 10px 12px 31px -14px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 12px 31px -14px rgba(0,0,0,0.75);
}

.visible{
  display: flex;
}

.invisible{
  display: none !important;
}

.testimonial_letra{
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A6E8A;
  border-radius: 50%;
}

.nav-wrapper > ul > li > a {
  color: #000000;
  font-size: 14px;
}

.borrar_horario:hover{
  background: #b8b8b836;
  width: 100%;
  transition: all ease-in-out 0.2s;
}

.borrar_horario{
  background: #ffffff;
  width: 100%;
  transition: all ease-in-out 0.2s;
}


/* selector de fecha*/
/* listo */
.Container_root__3qsfu{
  color: #235475 !important;
}

/* listo */
.MonthList_root__3BNZC{
  height: 631px !important;
}

/* listo */
.Header_root__VQJBt{
  /* background: rgb(35, 84, 117);
  background: -moz-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -webkit-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -o-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -ms-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: linear-gradient(to right, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%); */
  background: #235475 !important;
}

/* listo */
ul.Weekdays_root__BFsVa{
  /* background: rgba(35,84,117,1);
  background: -moz-linear-gradient(left, rgba(35,84,117,1) 0%, hsl(188, 78%, 45%) 100%);
  background: -webkit-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -o-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -ms-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: linear-gradient(to right, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%); */
  background: #235475 !important;
}

/* listo */
.Day_root__t6e32.Day_selected__1kQB_ .Day_selection__1gwtP{
  border-radius: 15px !important;
  font-size: 16px !important;
  width: 52px !important;
  height: 52px !important;
  margin-top: -26px !important;
  margin-left: -26px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}


.Day_root__t6e32.Day_enabled__JNrEz.Day_highlighted__nrT7e:before, .Day_root__t6e32.Day_enabled__JNrEz:active:before, .Day_root__t6e32.Day_enabled__JNrEz:hover:before{
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}
.Day_startOfEvenMonth__3nOay:hover{
  border-radius: 0 !important;
}
.Day_startOfMonth__3Bw_-:before{
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}
.Day_endOfEvenMonth__20Stu:hover{
  border-radius: 0 !important;
}
.Day_endOfMonth__2iWY9:before{
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}

.Month_row__3-dFQ{
  width: 100% !important;
}
.Day_root__t6e32{
  width: 14.28571% !important;
  font-size: 16px !important;
}
.Weekdays_day__Vk6fu{
  flex-basis: 14.28571% !important;
  color: #ffffff !important;
  font-size: 16px !important;
}
.Day_root__t6e32.Day_selected__1kQB_ .Day_selection__1gwtP .Day_day__3XJ2W{
  font-size: 18px !important;
}
.Day_root__t6e32.Day_selected__1kQB_ .Day_selection__1gwtP .Day_month__36oh8{
  font-size: 12px !important;
}

.text_contacto{
  font-size: 36px;
  line-height: 36px;
  color: #ffffff;
}

.horas{
  /* height: 500px; */
  overflow: scroll;
  display: flex;
}

.horas > div{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.horas > div > button{
  padding: 10px 50px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50px;
  background: #AED3F2;
  cursor: pointer;
}

.horas > .space{
  height: 150px;
}

.horas_selected{
  background: #22639A !important;
  color: #ffffff;
  border-radius: 15px;
}

.horas::-webkit-scrollbar {
  -webkit-appearance: none;
}

.horas::-webkit-scrollbar:vertical {
  width:8px;
}

.horas::-webkit-scrollbar-button:increment,.horas::-webkit-scrollbar-button {
  display: none;
} 

.horas::-webkit-scrollbar:horizontal {
  height: 10px;
}

.horas::-webkit-scrollbar-thumb {
  background: rgba(35,84,117,1);
  /* background: -moz-linear-gradient(top, rgba(35,84,117,1) 0%, rgba(25,180,204,1) 100%);
  background: -webkit-linear-gradient(top, rgba(35,84,117,1) 0%, rgba(25,180,204,1) 100%);
  background: -o-linear-gradient(top, rgba(35,84,117,1) 0%, rgba(25,180,204,1) 100%);
  background: -ms-linear-gradient(top, rgba(35,84,117,1) 0%, rgba(25,180,204,1) 100%);
  background: linear-gradient(to bottom, rgba(35,84,117,1) 0%, rgba(25,180,204,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#235475', endColorstr='#19b4cc', GradientType=0 ); */
  border-radius: 20px;
}

.horas::-webkit-scrollbar-track {
  border-radius: 10px;  
}
/* fin - selector fecha*/

.linea{
  position: relative;
  top: 45px;
  width: 1px;
  height: 270px;
  background: #858585;
}

.linea_1{
  position: relative;
  width: 75%;
  height: 1px;
  background: #858585;
  margin: 20px 50px;
}

.linea_2{
  width: 1px;
  height: 300px;
  background: #858585;
}

.div_nosotros{
  background-color: #235677;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  position: relative;
  top: -30px;
}

.div_nosotros_servicios{
  width: 450px;
  height: 300px;
  background-color: #ffffff;
  border-radius: 15px;
  margin: 20px;
  padding: 20px;
}

.div_nosotros > a > img {
  margin: 10px;
}

/*  imagenes  */
.imagen_1{
  width: 200px !important;
  height: 200px !important;
}

.imagenes_footer{
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border-radius: solid 1px #707070;
  background: #2A6E8A;
  margin: 10px;
}

.imagen_galeria.active {
  object-fit: contain !important;
}
/*  fin - imagenes  */

/*  fondos  */
.fondo_2{
  width: 100%;
  color: #ffffff;
  background: rgba(25,181,204,1);
  background: -moz-linear-gradient(left, rgba(25,181,204,1) 0%, rgba(35,85,119,1) 100%);
  background: -webkit-linear-gradient(left, rgba(25,181,204,1) 0%, rgba(35,85,119,1) 100%);
  background: -o-linear-gradient(left, rgba(25,181,204,1) 0%, rgba(35,85,119,1) 100%);
  background: -ms-linear-gradient(left, rgba(25,181,204,1) 0%, rgba(35,85,119,1) 100%);
  background: linear-gradient(to right, rgba(25,181,204,1) 0%, rgba(35,85,119,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#19b5cc', endColorstr='#235577', GradientType=1 );
}

.fondo_3{
  width: 100%;
  color: #ffffff;
  background: rgba(35,84,117,1);
  background: -moz-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -webkit-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -o-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: -ms-linear-gradient(left, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  background: linear-gradient(to right, rgba(35,84,117,1) 0%, rgba(25,181,204,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#235475', endColorstr='#19b5cc', GradientType=1 );
}

.fondo_div_3{
  padding:100px 8%;
}

/*  fin - fondos  */


/*  botones  */
.boton{
  background: rgba(35,85,119,1);
  cursor: pointer;
  /* background: -moz-linear-gradient(left, rgba(35,85,119,1) 0%, rgba(25,180,204,1) 100%);
  background: -webkit-linear-gradient(left, rgba(35,85,119,1) 0%, rgba(25,180,204,1) 100%);
  background: -o-linear-gradient(left, rgba(35,85,119,1) 0%, rgba(25,180,204,1) 100%);
  background: -ms-linear-gradient(left, rgba(35,85,119,1) 0%, rgba(25,180,204,1) 100%);
  background: linear-gradient(to right, rgba(35,85,119,1) 0%, rgba(25,180,204,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#235577', endColorstr='#19b4cc', GradientType=1 ); */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff !important;
}

.boton > a{
  color: #ffffff !important;
}

.boton_1{
  background: #19B5CC;
  color: #ffffff;
  padding: 0px 10px;
  border-radius: 5px;
  font-weight: bold;
  min-width: 200px;
  max-width: 300px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.boton_2{
  background: #235677;
  color: #ffffff;
  padding: 5px 20px;
  border-radius: 5px;
  display: inline;
  font-weight: bold;
}
/*  fin - botones  */


/*  formulario  */
.input-field>label:not(.label-icon).active{
  top: -10px;
}

.intl-tel-input{
  width: calc(100% - 58px);
}
.intl-tel-input.allow-dropdown input, .intl-tel-input.allow-dropdown input[type=text], .intl-tel-input.allow-dropdown input[type=tel]{
  border: 1px solid #b4b4b4 !important;
  border-radius: 10px !important;
}

.formulario{
  border: 1px solid #b4b4b4 !important;
  border-radius: 10px !important;
}

.formulario::placeholder{
  padding-left: 15px;
}

.formulario_opcion{
  width: 200px;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  border: 1px solid #b4b4b4;
}

.formulario_opcion > p{
  margin: 0px;
}

.activo{
  border: none !important;
  background-color: #235677;
  color: #ffffff;
}
/*  fin - formulario  */


/*  flexbox  */
.padre{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.padre_1{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.padre_2{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.padre_3{
  display: flex;
  align-items: center;
}

.padre_4{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
}

.hijo_servicios{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 15px;
}

.titulo_servicios{
  height: 150px;
  display: flex;align-items: center;
}
/*  fin - flexbox  */


/*  titulos y textos  */
.titulo_error{
  font-size: 180px;
  margin: 0px;
  line-height: 180px;
  font-weight: bolder;
  color: rgb(35, 86, 119);
}

.grueso_1{
  font-weight: 700;
}

span{
  font-weight: bold;
}

.texto_1{
  font-family: 'Rubik', sans-serif;
  text-transform: uppercase;
  color: #235677;
  letter-spacing: 2px;
  font-weight: bold;
}

.texto_2{
  font-size: 20px;
  font-weight: bold;
  color: #091D3E;
}
/*  fin - titulos y textos  */


/*  sombras  */
.sombras{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  padding: 50px 40px;
  border-radius: 10px;
  background: #ffffff;
}

.sombras_1{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  padding: 20px 40px;
  border-radius: 10px;
  background: #ffffff;
  width: 80%;
  margin: 25px;
}

.sombras_2{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  height: 80px;
  width: 270px;
  padding: 0px 20px;
  border-radius: 10px;
  background: #ffffff;
  margin: 25px;
}

.sombras_3{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  height: 100px;
  width: 100px;
  border-radius: 10px;
  background: #ffffff;
  margin: 25px;
}

.sombras_4{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  border-radius: 10px;
  background: #ffffff;
  margin: 25px;
}

.sombras_5{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  height: 120px;
  width: 270px;
  padding: 0px 20px;
  border-radius: 10px;
  background: #ffffff;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sombras_6{
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background: #ffffff;
}

.sombras_7{
  text-align: center;
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.75);
  padding: 0px 20px 25px 20px;
  border-radius: 10px;
  background: #ffffff;
  margin: 15px;
  padding: 20px;
}
/*  fin - sombras  */

.imagen_index{
  height: 65px;
}

.icons_contacto{
  width: 100%;
  max-width: 84px;
  height: 84px;
  background: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.25);
  -webkit-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.25);
  -moz-box-shadow: 0px 0px 22px -4px rgba(0,0,0,0.25);
}

@media only screen and (min-width:200px) and (max-width:550px){
  /*  fondos  */
  .fondo_1{
    background-image: url(./Images/1-4.jpg);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
  }

  .fondo_4{
    background-image: url(./Images/7-4.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
  }

  .fondo_5{
    background-image: url(./Images/9-4.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_6{
    background-image: url(./Images/10-4.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_7{
    background-image: url(./Images/12-4.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }
  /*  fin - fondos  */

  .sombras_7{
    width: 300px;
    height: 390px;
  }

  .imagen_index{
    height: 55px;
  }

  .fondo_2{
    padding:50px 20%;
    height: 400px;
  }

  .notificacion{
    width: 80% !important;
    height: 250px;
  }

  .contenedor{
    padding: 5%;
  }

  .linea{
    display: none;
  }

  .linea_2{
    display: none;
  }

  .index_doctora{
    position: relative;
    top: -50px;
    left: -10px;
  }

  .padding_0{
    padding: 0px !important;
  }

  .padding_1{
    padding: 20px 0px !important;
  }

  .padding_2{
    padding: 0px 0px 0px 200px !important;
  }

  /*  titulos y textos  */
  .titulo_error{
    font-size: 120px;
    line-height: 75px;
  }

  .titulo_1{
    font-size: 25px;
    color: #235677;
    text-align: center;
  }
  
  .titulo_2{
    font-size: 25px;
  }

  .titulo_3{
    font-size: 50px;
    font-weight: bold;
    color: #091D3E;
  }

  .texto_2{
    font-size: 15px;
  }
  /*  fin - titulos y textos  */

  /*  imagenes  */
  .contenedor_galeria_2{
    width: 150px;
    height: 150px;
  }

  .contenedor_galeria_2 > .material-placeholder{
    height: 150px;
  }

  .contenedor_galeria_2 > .material-placeholder > img{
    width: 100%;
    height: 150px;
    padding: 5px;
    object-fit: cover;
  }
  /*  fin - imagenes  */

  /*  flexbox  */
  .padre{
    margin-bottom: 30px;
  }

  .padre_3{
    flex-wrap: wrap;
  }

  .hijo_1{
    width: 100%;
  }

  .hijo_1_1{
    width: 100%;
  }

  .hijo_2{
    width: 100%;
  }
  
  .hijo_3{
    width: 100%;
  }

  .hijo_4{
    width: 250px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hijo_5{
    width: 100%;
    padding: 20px;
  }
  
  .hijo_6{
    width: 100%;
  }

  .hijo_7{
    width: 100%;
  }
  
  .hijo_8{
    width: 100%;
  }

  .hijo_servicios{
    width: 279px;
    margin: 15px;
    height: 360px;
  }
  /*  fin - flexbox  */

  .sombras_2{
    width: 292px;
  }

  .sombras_7{
    height: auto !important;
  }
}

@media only screen and (min-width:551px) and (max-width:850px){
  /*  fondos  */
  .fondo_1{
    background-image: url(./Images/1-3.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
  }

  .fondo_4{
    background-image: url(./Images/7-3.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_5{
    background-image: url(./Images/9-3.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_6{
    background-image: url(./Images/10-3.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_7{
    background-image: url(./Images/12-3.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }
  /*  fin - fondos  */

  .sombras_7{
    width: 280px;
    height: 420px;
  }

  .fondo_2{
    padding:50px 20%;
    height: 400px;
  }

  .contenedor{
    padding: 5%;
  }

  .linea{
    display: none;
  }

  .linea_2{
    display: none;
  }

  .index_doctora{
    position: relative;
    top: -50px;
    left: -10px;
  }

  /*  titulos y textos  */
  .titulo_1{
    font-size: 30px;
    color: #235677;
    text-align: center;
  }
  
  .titulo_2{
    font-size: 30px;
  }

  .titulo_3{
    font-size: 60px;
    font-weight: bold;
    color: #091D3E;
  }
  /*  fin - titulos y textos  */

  /*  imagenes  */
  .contenedor_galeria_2{
    width: 300px;
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder{
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder > img{
    width: 100%;
    height: 300px;
    padding: 15px;
    object-fit: cover;
  }
  /*  fin - imagenes  */

  /*  flexbox  */
  .hijo_1{
    width: 100%;
  }

  .hijo_1_1{
    width: 50%;
  }

  .hijo_2{
    width: 100%;
  }
  
  .hijo_3{
    width: 100%;
  }

  .hijo_4{
    width: 250px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hijo_5{
    width: 100%;
    padding: 20px;
  }
  
  .hijo_6{
    width: 100%;
  }

  .hijo_7{
    width: 100%;
  }
  
  .hijo_8{
    width: 100%;
  }

  .hijo_servicios{
    width: 279px;
    margin: 15px;
    height: 360px;
  }
  /*  fin - flexbox  */
}

@media only screen and (min-width:851px) and (max-width:1224px){
  /*  fondos  */
  .fondo_1{
    background-image: url(./Images/1-2.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
  }

  .fondo_4{
    background-image: url(./Images/7-2.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_5{
    background-image: url(./Images/9-2.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_6{
    background-image: url(./Images/10-2.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_7{
    background-image: url(./Images/12-2.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }
  /*  fin - fondos  */

  .sombras_7{
    width: 300px;
    height: 390px;
  }

  .fondo_2{
    padding:50px 20%;
    height: 400px;
  }

  .height_530{
    height: 530px;
  }
  
  .contenedor{
    padding: 5%;
  }

  .linea{
    display: none;
  }

  .linea_2{
    display: none;
  }

  .index_doctora{
    position: relative;
    top: -50px;
    left: -10px;
  }

  .padding_0{
    padding: 0px !important;
  }

  .padding_1{
    padding: 20px 0px !important;
  }

  .padding_2{
    padding: 0px 0px 0px 200px !important;
  }

  /*  titulos y textos  */
  .titulo_1{
    font-size: 30px;
    color: #235677;
    text-align: center;
  }
  
  .titulo_2{
    font-size: 30px;
  }

  .titulo_3{
    font-size: 60px;
    font-weight: bold;
    color: #091D3E;
  }
  /*  fin - titulos y textos  */

  /*  imagenes  */
  .contenedor_galeria_2{
    width: 300px;
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder{
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder > img{
    width: 100%;
    height: 300px;
    padding: 15px;
    object-fit: cover;
  }
  /*  fin - imagenes  */

  /*  flexbox  */
  .padre_3{
    flex-wrap: wrap;
  }
  
  .hijo_1{
    width: 100%;
  }

  .hijo_1_1{
    width: 50%;
  }

  .hijo_2{
    width: 100%;
  }
  
  .hijo_3{
    width: 100%;
  }

  .hijo_5{
    width: 100%;
    padding: 20px;
  }

  .hijo_4{
    width: 250px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .hijo_6{
    width: 100%;
  }

  .hijo_7{
    width: 33%;
  }
  
  .hijo_8{
    width: 67%;
  }

  .hijo_servicios{
    width: 279px;
    margin: 15px;
    height: 360px;
  }
  /*  fin - flexbox  */

  /*  sombras  */
  .sombras_2{
    width: 325px;
  }
  /*  fin - sombras  */
}

@media only screen and (min-width:1225px) and (max-width:1600px){
  /*  fondos  */
  .fondo_1{
    background-image: url(./Images/1-1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px;
  }

  .fondo_4{
    background-image: url(./Images/7-1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_5{
    background-image: url(./Images/9-1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_6{
    background-image: url(./Images/10-1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_7{
    background-image: url(./Images/12-1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }
  /*  fin - fondos  */

  .sombras_7{
    width: 300px;
    height: 390px;
  }

  .fondo_2{
    padding:50px 20%;
    height: 400px;
  }

  .height_530{
    height: 530px;
  }

  .contenedor{
    padding: 5%;
  }

  .contenedor_galeria{
    min-height: 500px;
  }
  
  .contenedor_galeria > img{
    width: 100%;
    min-height: 500px;
    object-fit: cover;
  }

  .index_doctora{
    position: relative;
    top: -50px;
    left: -40px;
  }

  .padding_0{
    padding: 0 50px 0 0 !important;
  }

  .padding_1{
    padding: 20px 0px !important;
  }

  .padding_2{
    padding: 0px 0px 0px 200px !important;
  }

  /*  titulos y textos  */
  .titulo_1{
    font-size: 30px;
    color: #235677;
    text-align: center;
  }
  
  .titulo_2{
    font-size: 30px;
  }

  .titulo_3{
    font-size: 60px;
    font-weight: bold;
    color: #091D3E;
  }
  /*  fin - titulos y textos  */

  /*  imagenes  */
  .contenedor_galeria_2{
    width: 300px;
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder{
    height: 300px;
  }

  .contenedor_galeria_2 > .material-placeholder > img{
    width: 100%;
    height: 300px;
    padding: 15px;
    object-fit: cover;
  }
  /*  fin - imagenes  */

  /*  flexbox  */
  .padre_3{
    flex-wrap: wrap;
  }
  
  .hijo_1{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 530px;
  }

  .hijo_1_1{
    width: 50%;
  }

  .hijo_2{
    width: 40%;
  }
  
  .hijo_3{
    width: 60%;
  }

  .hijo_4{
    width: 184px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hijo_5{
    width: 33%;
    padding: 20px;
  }
  
  .hijo_6{
    width: 67%;
  }

  .hijo_7{
    width: 33%;
  }
  
  .hijo_8{
    width: 67%;
  }

  .hijo_servicios{
    width: 275px;
    margin: 15px;
    height: 400px;
  }
  /*  fin - flexbox  */

  /*  sombras  */
  .sombras_2{
    width: 265px;
  }
  /*  fin - sombras  */
}

@media only screen and (min-width:1601px){

  /*  fondos  */
  .fondo_1{
    background-image: url(./Images/1.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 700px;
  }

  .fondo_4{
    background-image: url(./Images/7.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_5{
    background-image: url(./Images/9.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_6{
    background-image: url(./Images/10.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }

  .fondo_7{
    background-image: url(./Images/12.png);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 400px;
    filter: brightness(0.4);
  }
  /*  fin - fondos  */

  .sombras_7{
    width: 380px;
    height: 380px;
  }

  .fondo_2{
    padding:100px 20%;
    height: 500px;
  }

  .height_530{
    height: 530px;
  }

  .contenedor{
    padding: 10%;
  }

  .contenedor_galeria{
    min-height: 500px;
  }
  
  .contenedor_galeria > img{
    width: 100%;
    min-height: 500px;
    object-fit: cover;
  }

  .index_doctora{
    position: relative;
    top: -100px;
    left: -60px;
  }

  /*  titulos y textos  */
  .titulo_1{
    font-size: 40px;
    color: #235677;
    text-align: center;
  }
  
  .titulo_2{
    font-size: 40px;
  }

  .titulo_3{
    font-size: 60px;
    font-weight: bold;
    color: #091D3E;
  }
  /*  fin - titulos y textos  */

  /*  imagenes  */
  .contenedor_galeria_2{
    width: 400px;
    height: 400px;
  }

  .contenedor_galeria_2 > .material-placeholder{
    height: 400px;
  }

  .contenedor_galeria_2 > .material-placeholder > img{
    width: 100%;
    height: 400px;
    padding: 15px;
    object-fit: cover;
  }
  /*  fin - imagenes  */

  /*  flexbox  */
  .hijo_1{
    width: 50%;
  }

  .hijo_1_1{
    width: 50%;
  }

  .hijo_2{
    width: 40%;
  }
  
  .hijo_3{
    width: 60%;
  }

  .hijo_4{
    width: 250px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hijo_5{
    width: 33%;
    padding: 20px;
  }
  
  .hijo_6{
    width: 67%;
  }

  .hijo_7{
    width: 33%;
  }
  
  .hijo_8{
    width: 67%;
  }

  .hijo_servicios{
    width: 350px;
    margin: 15px;
    height: 400px;
  }
  /*  fin - flexbox  */
}